import React from 'react';
import { string } from 'prop-types';
import { Flex, Box } from '@chakra-ui/react';

import useDateCountdown from '@/hooks/useDateCountdown';
import WithAnimation from '@components/Common/WithAnimation';
import CountItem from './CountItem';

import { txtDay, txtHour, txtMinutes, txtSeconds } from './locales';

function CountContainer({ lang }) {
  const { days, hours, minutes, seconds } = useDateCountdown();

  return (
    <Box padding="16px 0">
      <Box padding="0 32px">
        <Flex justifyContent="center">
          <Flex alignItems="center" justifyContent="space-between" maxWidth="300px">
            <WithAnimation top>
              <CountItem text={txtDay[lang]} number={days} />
            </WithAnimation>
            <WithAnimation bottom>
              <CountItem text={txtHour[lang]} number={hours} />
            </WithAnimation>
            <WithAnimation top>
              <CountItem text={txtMinutes[lang]} number={minutes} />
            </WithAnimation>
            <WithAnimation bottom>
              <CountItem text={txtSeconds[lang]} number={seconds} />
            </WithAnimation>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

CountContainer.propTypes = {
  lang: string,
};

CountContainer.defaultProps = {
  lang: 'id',
};

export default React.memo(CountContainer);
