import React from 'react';

import { BG_SECONDARY } from '@/constants/colors';
import { Box } from '@chakra-ui/react';

function Wave({ ...rest }) {
  let WAVE = (
    <svg
      width="100%"
      height="40px"
      viewBox="0 0 1280 140"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={BG_SECONDARY}>
        <path
          d="M725.29 101.2C325.22 122.48 0 0 0 0v140h1280V0s-154.64 79.92-554.71 101.2z"
          fillOpacity=".3"
        />
        <path
          d="M556.45 119.74C953.41 140 1280 14 1280 14v126H0V0s159.5 99.48 556.45 119.74z"
          fillOpacity=".5"
        />
        <path d="M640 140c353.46 0 640-140 640-139v140H0V0s286.54 140 640 140z" />
      </g>
    </svg>
  );
  return (
    <Box width="100%" transform="translateY(-38px)" height="38px" {...rest}>
      {WAVE}
    </Box>
  );
}

export default React.memo(Wave);
