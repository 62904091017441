import P1 from '@/images/2/1.jpg';
import P2 from '@/images/2/2.jpg';
import P3 from '@/images/2/3.jpg';
import P4 from '@/images/2/4.jpg';
import P5 from '@/images/2/5.jpg';
import P6 from '@/images/2/6.jpg';
import P7 from '@/images/2/7.jpg';
import P8 from '@/images/2/8.jpg';

export const photos = [
  {
    original: P1,
    // thumbnail: P1,
  },
  {
    original: P2,
    // thumbnail: P2,
  },
  {
    original: P3,
    // thumbnail: P3,
  },
  {
    original: P4,
    // thumbnail: P4,
  },
  {
    original: P5,
    // thumbnail: P5,
  },
  {
    original: P6,
    // thumbnail: P6,
  },
  {
    original: P7,
    // thumbnail: P7,
  },
  {
    original: P8,
    // thumbnail: P8,
  },
];
