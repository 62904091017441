import React, { useState, useCallback } from 'react';
import { Box } from '@chakra-ui/react';

import CoverSection from '@components/Section/Cover';
import QuranSection from '@components/Section/Quran';
import Welcome from '@components/Section/Welcome';
import CoupleInfoSection from '@components/Section/CoupleInfo';
import CountingDown from '@components/Section/CountingDown';
import WeddingSection from '@components/Section/Wedding';
import RSVPSection from '@components/Section/RSVP/Lazy';
import YoutubeLive from '@components/Section/YoutubeLive/Lazy';
import LoveStory from '@components/Section/LoveStory/Lazy';
import GiftCard from '@components/Section/GiftCard/Lazy';
import WishesSection from '@components/Section/Wishes';
import PhotoGallery from '@components/Section/PhotoGallery';
import Footer from '@components/Section/Footer';
import QRCard from '@components/Common/QRCard/V2/Lazy';

import CovidProtocol from '@components/Common/CovidModal/Lazy';
import DarkmodeNotification from '@components/Common/DarkmodeNotification/Lazy';
import FloatingMusic from '@components/Common/FloatingMusic/Lazy';
import Layout from '@components/Layout';
import Navigation from '@components/Common/Navigation';
import WithAnimation from '@components/Common/WithAnimation';

import useGuestInfo from '@hooks/useGuestInfo';
import useInvitation from '@/hooks/useInvitation';
import useLang from '@/hooks/useLang';
import * as ID from '../constants/identifier';
import * as FEATURE from '../constants/feature-flags';

const ELEMENT_CONTENT_ID = 'invitato-detail-content';

export default function Home() {
  const { code } = useGuestInfo();
  const [showDetailContent, setShowDetailContent] = useState(false);
  const invitation = useInvitation();
  const lang = useLang();

  /**
   * function to show detail invitation
   * @returns {void}
   */
  const handleClickDetail = useCallback(() => {
    setShowDetailContent(true);
  }, []);

  /**
   * function to render invitation content
   * @returns {JSX.Element}
   */
  const renderContent = () => {
    if (!showDetailContent) return null;

    return (
      <Box id={ELEMENT_CONTENT_ID}>
        <Welcome lang={lang} />
        <QuranSection lang={lang} />
        <CoupleInfoSection name={ID.ID_COUPLE_SECTION} lang={lang} />
        <CountingDown lang={lang} />
        <WeddingSection name={ID.ID_DETAIL_SECTION} lang={lang} />
        {FEATURE.ENABLE_QR_INVITATION && code && <QRCard padding="16px 24px" name={ID.ID_QRCODE} />}
        <YoutubeLive lang={lang} name={ID.ID_YOUTUBE_LIVE} />
        {FEATURE.ENABLE_GIFT_CARD && <GiftCard lang={lang} name={ID.ID_GIFT_SECTION} />}
        {FEATURE.ENABLE_RSVP && invitation && <RSVPSection lang={lang} name={ID.ID_RSVP_SECTION} />}
        {FEATURE.ENABLE_LOVE_STORY && <LoveStory lang={lang} />}
        <PhotoGallery lang={lang} />
        <WishesSection lang={lang} />
        <Footer lang={lang} />
      </Box>
    );
  };

  return (
    <Layout>
      {FEATURE.ENABLE_MUSIC_BACKSOUND && <FloatingMusic shouldShowMusicIcon={showDetailContent} />}
      {FEATURE.ENABLE_DARKMODE_NOTIFICATION && <DarkmodeNotification lang={lang} />}
      {showDetailContent && <Navigation lang={lang} />}
      {FEATURE.ENABLE_COVID_PROTOCOL && showDetailContent && invitation && (
        <CovidProtocol visible={showDetailContent} lang={lang} />
      )}
      <WithAnimation top collapse when={!showDetailContent}>
        <CoverSection loaded={showDetailContent} onSeeDetail={handleClickDetail} />
      </WithAnimation>
      {renderContent()}
    </Layout>
  );
}
