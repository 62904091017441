import React from 'react';
import { Box } from '@chakra-ui/react';
import { bool } from 'prop-types';
import { WEDDING_BORDER } from './BorderSvg';

function BorderFrame({isHeader, isFooter, ...rest }) {
    return (
        <Box
            width={isHeader ? "calc(100% - 64px)" : isFooter ? "100%" : "calc(100% + 48px)"} 
            height={isHeader || isFooter ? "42px" : "100%"}
            bgSize={"100%"}
            bgRepeat="repeat-y"
            bgImage={`url("data:image/svg+xml;utf8,${encodeURIComponent(WEDDING_BORDER)}")`} 
            bgPos={isHeader ? "top" : isFooter ? "bottom" : "center -24px"} 
            position="absolute" 
            {...rest}
        />
    )
}

BorderFrame.propTypes = {
    isHeader: bool,
    isFooter: bool,
};

BorderFrame.defaultProps = {
    isHeader: false,
    isFooter: false,
};

export default BorderFrame;
